var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 mt-2"},[_c('b-tabs',{attrs:{"content-class":"pt-1 mb-4"}},[_c('b-tab',{attrs:{"title":"Contabilità","active":"","title-item-class":"lisaweb"}},[_c('b-row',[(!_vm.isLoading)?_c('div',{staticClass:"col-md-4 mt-2"},[_c('base-radio',{attrs:{"vid":"progressive","name":"progressive","label":"Foglio Quadratura Progressivo","options":Object.values(
                _vm.beForm['agency_setup'].attribute_ACCT_value.options
              ).map((o) => {
                return { value: o.value, text: o.text };
              })},on:{"select":_vm.onProgressiveChange},model:{value:(_vm.form['agency_setup'].attribute_ACCT_value),callback:function ($$v) {_vm.$set(_vm.form['agency_setup'], "attribute_ACCT_value", $$v)},expression:"form['agency_setup'].attribute_ACCT_value"}})],1):_vm._e()]),_vm._v(" "),_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(_vm._s(_vm.msg || "Operazione in corso..."))])],1)]},proxy:true}])},[_c('b-row',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading && _vm.isProgressiveSelected),expression:"!isLoading && isProgressiveSelected"}],staticClass:"col-md-12"},[_c('b-card',{attrs:{"header":"true","header-tag":"header"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('p',{staticClass:"mb-0 d-inline-block align-middle"},[_c('b',[_vm._v("\n                    "+_vm._s(_vm.canConsolidate
                        ? "Nuova Apertura Saldi"
                        : "Saldi per il giorno " +
                          _vm.toLocaleDate(_vm.consolidatedAt))+"\n                  ")])])]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-md-4"},[_c('base-datepicker',{attrs:{"vid":"consolidated_at","name":"consolidated_at","label":_vm.canConsolidate
                        ? 'Data di nuova apertura saldi'
                        : 'Data saldi',"max":_vm.today()},on:{"select":_vm.onDate},model:{value:(_vm.form.consolidated_at),callback:function ($$v) {_vm.$set(_vm.form, "consolidated_at", $$v)},expression:"form.consolidated_at"}})],1),_vm._v(" "),(_vm.maxDate)?_c('div',{staticClass:"col-md-4"},[_c('base-datepicker',{attrs:{"vid":"last_consolidated_at","name":"last_consolidated_at","label":'Data di ultima apertura saldi',"disabled":true},model:{value:(_vm.maxDate),callback:function ($$v) {_vm.maxDate=$$v},expression:"maxDate"}})],1):_vm._e()]),_vm._v(" "),(
                  _vm.isProgressiveSelected &&
                  _vm.consolidatedAt &&
                  (_vm.canConsolidate || _vm.readonlyConsolidated)
                )?_c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('p',{staticClass:"text-uppercase"},[_c('b',[_vm._v("\n                      "+_vm._s(_vm.canConsolidate
                          ? "Valori nuova apertura saldi"
                          : "Valori saldi per il giorno " +
                            _vm.toLocaleDate(_vm.consolidatedAt))+"\n                    ")])]),_vm._v(" "),(Object.keys(_vm.form['consolidated_treasury']).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"verticalLine"},[_vm._v("Compagnia")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'N',
                            'N',
                            'N',
                            'N',
                            'title'
                          )),function(item,index){return _c('th',{key:item.code,class:{
                            verticalLine:
                              index ===
                              _vm.getSectorCodes(_vm.sectorIndex, 'N', 'N', 'N', 'N')
                                .length -
                                1,
                          }},[_vm._v("\n                          "+_vm._s(item)+"\n                        ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'Y',
                            'N',
                            'N',
                            'N',
                            'title'
                          )),function(item,index){return _c('th',{key:item.code,class:{
                            verticalLine:
                              index ===
                              _vm.getSectorCodes(_vm.sectorIndex, 'Y', 'N', 'N', 'N')
                                .length -
                                1,
                          }},[_vm._v("\n                          "+_vm._s(item)+"\n                        ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'Y',
                            'Y',
                            'N',
                            'N',
                            'title'
                          )),function(item){return _c('th',{key:item.code},[_vm._v("\n                          "+_vm._s(item)+"\n                        ")])})],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.form[
                          'consolidated_treasury'
                        ]),function(treasuryGross,id){return _c('tr',{key:'tr-' + id},[_c('td',{class:{
                            verticalLine: true,
                            mw: true,
                          }},[_vm._v("\n                          "+_vm._s(parseInt(id)
                              ? `${_vm.insurers.find((e) => e.id == id).code} - ${
                                  _vm.insurers.find((e) => e.id == id).title
                                }`
                              : "Indefinita")+"\n                        ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'N',
                            'N',
                            'N',
                            'N'
                          )),function(code,index1){return _c('td',{key:'1.' + index1,class:{
                            verticalLine:
                              index1 ===
                              _vm.getSectorCodes(_vm.sectorIndex, 'N', 'N', 'N', 'N')
                                .length -
                                1,
                            xsw: true,
                          }},[_c('base-currency',{attrs:{"name":'gross-' + id + '-' + code,"vid":'gross-' + id + '-' + code,"label":"","options":{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            },"readonly":_vm.readonlyConsolidated},model:{value:(_vm.form[_vm.ct][id][code]),callback:function ($$v) {_vm.$set(_vm.form[_vm.ct][id], code, $$v)},expression:"form[ct][id][code]"}})],1)}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'Y',
                            'N',
                            'N',
                            'N'
                          )),function(code,index2){return _c('td',{key:'2.' + index2,class:{
                            verticalLine:
                              index2 ===
                              _vm.getSectorCodes(_vm.sectorIndex, 'Y', 'N', 'N', 'N')
                                .length -
                                1,
                            xsw: true,
                          }},[_c('base-currency',{attrs:{"name":'gross-' + id + '-' + code,"vid":'gross-' + id + '-' + code,"label":"","options":{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            },"readonly":_vm.readonlyConsolidated},model:{value:(_vm.form[_vm.ct][id][code]),callback:function ($$v) {_vm.$set(_vm.form[_vm.ct][id], code, $$v)},expression:"form[ct][id][code]"}})],1)}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'Y',
                            'Y',
                            'N',
                            'N'
                          )),function(code,index3){return _c('td',{key:'3.' + index3,class:{ xsw: true }},[_c('base-currency',{attrs:{"name":'gross-' + id + '-' + code,"vid":'gross-' + id + '-' + code,"label":"","options":{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            },"readonly":_vm.readonlyConsolidated},model:{value:(_vm.form[_vm.ct][id][code]),callback:function ($$v) {_vm.$set(_vm.form[_vm.ct][id], code, $$v)},expression:"form[ct][id][code]"}})],1)})],2)}),_vm._v(" "),_c('tr',{staticClass:"totals"},[_c('td',{staticClass:"verticalLine"},[_vm._v("Totale")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'N',
                            'N',
                            'N',
                            'N'
                          )),function(code,index1){return _c('td',{key:'T1.' + index1,class:{
                            verticalLine:
                              index1 ===
                              _vm.getSectorCodes(_vm.sectorIndex, 'N', 'N', 'N', 'N')
                                .length -
                                1,
                          }},[_vm._v("\n                          "+_vm._s(_vm.toLocaleCurrency(_vm.getSectorGrossTotals(code)))+"\n                        ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'Y',
                            'N',
                            'N',
                            'N'
                          )),function(code,index2){return _c('td',{key:'T2.' + index2,class:{
                            verticalLine:
                              index2 ===
                              _vm.getSectorCodes(_vm.sectorIndex, 'Y', 'N', 'N', 'N')
                                .length -
                                1,
                          }},[_vm._v("\n                          "+_vm._s(_vm.toLocaleCurrency(_vm.getSectorGrossTotals(code)))+"\n                        ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                            _vm.sectorIndex,
                            'Y',
                            'Y',
                            'N',
                            'N'
                          )),function(code,index3){return _c('td',{key:'T3.' + index3},[_vm._v("\n                          "+_vm._s(_vm.toLocaleCurrency(_vm.getSectorGrossTotals(code)))+"\n                        ")])})],2)],2)]):_c('div',[_vm._v("Nessun movimento da mostrare.")]),_vm._v(" "),_c('div',{staticClass:"mt-2"},[(_vm.canConsolidate && !_vm.readonlyConsolidated)?_c('b-button',{staticClass:"float-left",attrs:{"variant":"lisaweb"},on:{"click":function($event){return _vm.onSubmit(_vm.ct)}}},[_vm._v("Salva")]):_vm._e()],1)])],1):_vm._e()])],1)])],1)],1),_vm._v(" "),_c('b-tab',{attrs:{"title":"Altro","title-item-class":"lisaweb"}},[_c('b-row',[(!_vm.isLoading)?_c('div',{staticClass:"col-md-4 mt-2"},[_c('base-radio',{attrs:{"vid":"broker_statement","name":"broker_statement","label":"Modalità Rendiconto","options":_vm.beForm['agency_setup'].attribute_STATMOD_value.options},on:{"select":_vm.onBrokerStatementChange},model:{value:(_vm.form['agency_setup'].attribute_STATMOD_value),callback:function ($$v) {_vm.$set(_vm.form['agency_setup'], "attribute_STATMOD_value", $$v)},expression:"form['agency_setup'].attribute_STATMOD_value"}})],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }